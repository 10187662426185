<template>
  <div class="modal fade" ref="highlightModal" id="highlightModal" tabindex="-1" role="dialog"
    aria-labelledby="highlightModal" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="d-flex justify-content-between align-items-center px-3 text-black-50 border-bottom">
          <small>{{ $t("js.highlight_modal.header") }}</small>
          <button type="button" class="btn btn-link text-black-50" data-bs-dismiss="modal">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="modal-body">
          <div v-if="activeHighlight">
            <PresentPost v-if="highlightedPost" :post="highlightedPost" class="mb-3" />
            <div v-if="activeHighlight.highlight_message">
              <label class="font-weight-bold">
                {{ $t("js.highlight_modal.message_label_frontend") }}
              </label>
              <div style="white-space: pre-wrap; overflow-wrap: break-word;">
                {{ activeHighlight.highlight_message }}
              </div>
            </div>
          </div>
          <div v-else>
            {{ $t("js.highlight_modal.no_active_highlight") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapState } from "pinia";
import PresentPost from "@/components/shared/PresentPost.vue";
import { useVotingModulesStore } from "@/entrypoints/stores/voting_modules";

export default defineComponent({
  name: "HighlightModal",
  components: { PresentPost },
  computed: {
    ...mapState(useVotingModulesStore, ["activeHighlight"]),
    highlightedPost() {
      return this.activeHighlight.highlight_post;
    },
  },
  watch: {
    activeHighlight: {
      handler(newHighlight, oldHighlight) {
        if (!newHighlight) {
          $("#highlightModal").modal("hide");
        } else if (!oldHighlight || Object.values(newHighlight).toString() !== Object.values(oldHighlight).toString()) $("#highlightModal").modal("show");
      }, deep: true,
    },
  },
});
</script>
